<template>
  <div id="Footer">
    <footer class="un-bottom-navigation filter-blur d-lg-none d-block">
      <div class="em_body_navigation border-0">
        <div class="item_link">
          <router-link
            to="/category"
            class="btn btn_navLink visited"
            aria-label="btnNavigation"
          >
            <div class="icon_current">
              <i class="ri-apps-2-line"></i>
            </div>
            <div class="icon_active">
              <i class="ri-apps-2-fill"></i>
            </div>
          </router-link>
        </div>

        <div class="item_link">
          <router-link
            to="/search"
            class="btn btn_navLink visited"
            aria-label="btnNavigation"
          >
            <div class="icon_current">
              <i class="ri-search-2-line"></i>
            </div>
            <div class="icon_active">
              <i class="ri-search-2-fill"></i>
            </div>
          </router-link>
        </div>
        <div class="item_link">
          <router-link to="/" class="btn btn_navLink without_active">
            <div class="btn btnCircle_default">
              <i class="ri-home-5-line"></i>
            </div>
          </router-link>
        </div>

        <div class="item_link">
          <router-link
            exact
            to="/blog"
            class="btn btn_navLink visited"
            aria-label="btnNavigation"
          >
            <div class="icon_current">
              <i class="ri-article-line"></i>
            </div>
            <div class="icon_active">
              <i class="ri-home-5-fill"></i>
            </div>
          </router-link>
        </div>

        <div class="item_link">
          <a
            :href="$router.resolve({ name: 'Shortcut' }).href"
            class="btn btn_navLink visited"
            aria-label="btnNavigation"
          >
            <div class="icon_current">
              <i class="ri-user-4-line"></i>
            </div>
            <div class="icon_active">
              <i class="ri-user-4-fill"></i>
            </div>
          </a>
        </div>
      </div>
    </footer>

    <footer class="py-5 bg-light border-2 border-dark d-lg-block d-none">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 mb-3">
            <router-link
              class="
                d-inline-flex
                align-items-center
                mb-2
                link-dark
                text-decoration-none
                un-item-logo
              "
              to="/"
              aria-label="Bootstrap"
            >
              <img
                class="logo-img light-mode"
                width="159"
                height="34"
                src="/images/logo_b.svg"
                alt=""
              />
              <img
                class="logo-img dark-mode"
                width="159"
                height="34"
                src="/images/logo-white.svg"
                loading="lazy"
                alt=""
              />
            </router-link>
            <ul class="list-unstyled small text-muted">
              <li class="mb-2">
                Designed and built with all the love <br />
                in the world by the
                <span class="text-dark fw-bold">Dino team</span> .
              </li>
              <li class="mb-2">Beta V1</li>
            </ul>
          </div>
          <div class="col-lg-2 mb-3">
            <h1 class="h5 title-gradient">Products</h1>
            <ul class="list-unstyled mt-3">
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/search"
                  >Search</router-link
                >
              </li>
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/nft"
                  >NFT</router-link
                >
              </li>
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/token"
                  >Token Explorer</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/category"
                  >Category</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/airdrop"
                  >Airdrop</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-2 mb-3">
            <h1 class="h5 title-gradient">Company</h1>
            <ul class="list-unstyled mt-3">
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/"
                  >About Us</router-link
                >
              </li>
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/press"
                  >Press</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/disclaimer"
                  >Disclaimer</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/privacy-policy"
                  >Privacy Policy</router-link
                >
              </li>
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/terms"
                  >Terms</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-2 mb-3">
            <h1 class="h5 title-gradient">Resources</h1>
            <ul class="list-unstyled mt-3">
              <li class="mb-2">
                <router-link class="text-dark text-decoration-none" to="/blog"
                  >Blog</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/industry-overview"
                  >Industry Overview</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/reports"
                  >Reports</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-2 mb-3">
            <h1 class="h5 title-gradient">Developer</h1>
            <ul class="list-unstyled mt-3">
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/add-dapps"
                  >Submit a project</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  class="text-dark text-decoration-none"
                  to="/advertise"
                  >Advertising</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="col-12 fw-bold">Copyright 2022 - Dino Dapps</div>
      </div>
    </footer>

    <div
      class="modal transition-bottom screenFull defaultModal mdlladd__rate fade"
      id="mdllUploadItem"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="title-modal">Upload dApps</h1>
            <button
              type="button"
              class="btn btnClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="ri-close-fill"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="content-upload-item text-center">
              <p>Check this link</p>
              <div class="grid_buttonUpload">
                <button
                  data-bs-dismiss="modal"
                  @click="$router.push('/add-dapps')"
                  class="btn btn-create bg-primary text-white margin-b-20"
                >
                  Submit dApps
                </button>
                <a
                  href="/how-to-submit"
                  class="
                    btn btn-create
                    bg-white
                    border border-solid border-snow
                    text-dark
                  "
                >
                  How To Submit dApps
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <div class="env-pb"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.swiper {
  overflow: scroll hidden;
}
.swiper::-webkit-scrollbar {
  display: none;
}
</style>
